<template>
  <div>
    <v-form>
      <p class="mb-3">
        Evaluate the spectrum for compound C based on what you already know from the UN/DBE and IR
        spectrum. Which oxygen-containing functional group is the
        <chemical-latex content="^{13}C" />
        spectrum consistent with?
      </p>
      <p>
        functional group:
        <v-text-field v-model="inputs.studentAnswer" style="display: inline-block; width: 15em" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LBA2_M3Q5a',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
};
</script>
